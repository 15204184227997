import React from "react";
import "./loader.css";

export const Loader = () => (
  <div className="square2 ">
    <span></span>
    <span></span>
    <span></span>
  </div>
);
